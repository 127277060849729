import MultiLevel from "./components/MultiLevel";
import { FaCircle } from "react-icons/fa";
import { MdFullscreen } from "react-icons/md";
import { useEffect, useState } from 'react';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLastSubMenuChange = (label) => {
    setSelectedLabel(label);
  };

  const videoSources = {
    "Waterfall": "https://firebasestorage.googleapis.com/v0/b/seeyouinspace.appspot.com/o/Historic%20Waterfall%20-%20Roswell%2C%20Georgia-001.mp4?alt=media&token=57b84eb3-b829-46e8-8d3c-f26a0fda7287",
    "Jump": "https://firebasestorage.googleapis.com/v0/b/seeyouinspace.appspot.com/o/Jump%20-%20Second%20Scene.mp4?alt=media&token=9404386d-ce1c-45bf-b978-013efc042ebf",
    "Grand Canal": "https://firebasestorage.googleapis.com/v0/b/seeyouinspace.appspot.com/o/Grand%20Canal%20-%20Venice%2C%20Italy.mp4?alt=media&token=ed0f02e3-52a0-40eb-9e9d-d23f7d8ad868",
    "Trevi Fountain": "https://firebasestorage.googleapis.com/v0/b/seeyouinspace.appspot.com/o/Trevi%20Fountain%20-%20Rome%2C%20Italy.mp4?alt=media&token=549aea16-b94e-422e-94a3-b42e7e210e47",  
    "Urban Farmer": "https://firebasestorage.googleapis.com/v0/b/seeyouinspace.appspot.com/o/Urban%20Farmer%20Interview%20-%20Atlanta%20Harvest.mp4?alt=media&token=af778901-342f-4814-94f4-c4c9d0b0cdc6",   
  };

  useEffect(() => {
    console.log("Selected Label changed:", selectedLabel);
  }, [selectedLabel]);

  return (
    <>
      <div className="ui-container">
        {!isOpen ? (
          <FaCircle size={50} className="cursor-pointer" onClick={toggleMenu} />
        ) : (
          <MdFullscreen size={50} className="cursor-pointer" onClick={toggleMenu} />
        )}
        {isOpen && <MultiLevel onLastSubMenuLabelChange={handleLastSubMenuChange} />}
      </div>

      <a-scene>
        <a-assets>
          <video
            id="waterfall"
            preload="auto"
            src={selectedLabel && videoSources[selectedLabel] ? videoSources[selectedLabel] : "https://firebasestorage.googleapis.com/v0/b/seeyouinspace.appspot.com/o/Grand%20Canal%20-%20Venice%2C%20Italy.mp4?alt=media&token=ed0f02e3-52a0-40eb-9e9d-d23f7d8ad868"}
            width="100%"
            height="100%"
            autoPlay
            loop={true}
            crossOrigin="anonymous"
          ></video>
        </a-assets>

        <a-entity
          material="shader: flat; src: #waterfall"
          geometry="primitive: plane; width: 100%; height: 100%"
          position="0 -60 -200"
          rotation="0 -35 0"
          visible="false"
        ></a-entity>

        <a-camera></a-camera>
      </a-scene>
    </>
  );
}

export default App;
