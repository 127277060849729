import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { menuData } from "../data"; // Assuming menuData is defined elsewhere
import "./multi.css";

const customStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { isSelected }) => ({
    ...styles,
    color: isSelected ? 'white' : 'black'
  }),
  placeholder: (styles) => ({ ...styles, color: 'gray' }),
  clearIndicator: () => ({
    color: 'gray'
  })
};

function MultiLevelFilter({ onLastSubMenuLabelChange }) {
  const [selectedLabel, setSelectedLabel] = useState(null); 
  const [currentSubMenu, setCurrentSubMenu] = useState([]); 

  const hasSubmenus = (items) => {
    for (const item of items) {
      if (item.submenu && item.submenu.length > 0) {
        return true;
      }
    }
    return false;
  };
  const getSubmenuByLabel = (label, items) => {
    for (const item of items) {
      if (item.label === label) {
        return item.submenu || [];
      }
      if (item.submenu) {
        const subSubMenu = getSubmenuByLabel(label, item.submenu);
        if (subSubMenu.length > 0) {
          return subSubMenu;
        }
      }
    }
    return [];
  };
  useEffect(() => {
    if (selectedLabel) {
      const newSubMenu = getSubmenuByLabel(selectedLabel.value, menuData);
      setCurrentSubMenu(newSubMenu);
      if (!hasSubmenus(newSubMenu)) {
        onLastSubMenuLabelChange(selectedLabel.value);

      }
    } else {
      setCurrentSubMenu([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLabel, menuData]);

  const handleLabelChange = (selectedOption) => {
    setSelectedLabel(selectedOption);
  };

  const options = currentSubMenu.length > 0
    ? currentSubMenu.map(item => ({ label: item.label, value: item.label }))
    : selectedLabel
      ? []
      : menuData.map(item => ({ label: item.label, value: item.label }));

  return (
    <div>
      <Select
        styles={customStyles}
        isMulti={false} 
        value={selectedLabel} 
        options={options}
        onChange={handleLabelChange}
        isClearable
        menuIsOpen
      />
    </div>
  );
}

export default MultiLevelFilter;