export const menuData = [
    { label: "Travel",
      submenu:[
        { label:"Georgia",
          submenu:[
            { label:"Roswell",
              submenu:[{ label:"Waterfall"}]
            },
            { label:"Ellenwood",
              submenu:[{ label:"Atlanta Harvest Tent"}]
            }
          ]
        },
        { label:"Italy",
          submenu:[
            { label:"Venice",
              submenu:[{ label:"Grand Canal"}]
            },
            { label:"Rome",
              submenu:[{ label:"Trevi Fountain"}]
            }
          ]
        }
      ]
    },
    {
      label: "Education",
      submenu: [{ label: "Urban Farmer" }],
    },
    {
      label: "Entertainment",
      submenu: [{ label: "Jump" }],
    },
  ];
  